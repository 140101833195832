import styled from 'styled-components'

import Picture from '../../atoms/Picture'
import { mq, sizes } from '../../../theme'

export enum PictureEditoVariant {
  Ruby = 'Ruby',
  Yellow = 'Yellow',
}

export const Container = styled.section`
  width: 100%;
`

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 1.5rem;

  @media ${mq(sizes.tablet)} {
    padding: 8rem 0 6rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 3rem 2rem;
  border-radius: 0 0 2rem 2rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};

  @media ${mq(sizes.tablet)} {
    width: 66rem;
    padding: 3rem;
    border-radius: 4rem;
  }
  @media ${mq(sizes.desktop)} {
    width: 76rem;
  }
`

export const Intro = styled.p<{ $variant: PictureEditoVariant }>`
  ${({ theme }) => theme.textStyles.titleH8};
  color: ${({ theme, $variant }) =>
    $variant === PictureEditoVariant.Ruby
      ? theme.colors.pureWhite
      : theme.colors.arapawa};
  text-transform: uppercase;
  background-color: ${({ theme, $variant }) =>
    $variant === PictureEditoVariant.Ruby
      ? theme.colors.ruby
      : theme.colors.salomie};
  padding: 0.8rem 2rem;
  border-radius: 0 0 1rem 1rem;
  position: absolute;
  top: 0.5rem;
`

export const IntroLine = styled.div<{ $variant: PictureEditoVariant }>`
  height: 0.5rem;
  width: 25rem;
  background-color: ${({ theme, $variant }) =>
    $variant === PictureEditoVariant.Ruby
      ? theme.colors.ruby
      : theme.colors.salomie};
  border-radius: 0 0 1rem 1rem;
  position: absolute;
  top: 0;

  @media ${mq(sizes.tablet)} {
    padding: 0;
    width: 60rem;
  }
`

export const Title = styled.h3`
  ${({ theme }) => theme.textStyles.titleH3};
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;
  margin: 4rem 0 2rem 0;
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.textMedium};
  color: ${({ theme }) => theme.colors.bismark};
  margin-bottom: 2rem;
`

export const BackgroundImage = styled(Picture)`
  display: block;
  height: 100%;
  width: 100%;
  height: 21rem;

  & img {
    border-radius: 2rem 2rem 0 0;
    object-fit: cover;
    display: block;
    height: 100%;
    width: 100%;
  }

  @media ${mq(sizes.tablet)} {
    z-index: -1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;

    & img {
      border-radius: 4rem;
    }
  }
`

export const DesktopBackgroundImage = styled(BackgroundImage)`
  display: none;
  @media ${mq(sizes.tablet)} {
    display: block;
  }
`
export const MobileBackgroundImage = styled(BackgroundImage)`
  @media ${mq(sizes.tablet)} {
    display: none;
  }
`
